<template>
    <div>
        <p>
            <span class="h3">
                {{ lesson.lesson.lesson.title }}
            </span>
        </p>

        <b-skeleton
            v-if="isLoading"
            width="100%"
            height="250px"
            type="button"
            class="profileBox profileBox--2"
        ></b-skeleton>
        <div
            v-else-if="
                lesson.showHWButton && !isBlockedHomework && lesson.homeTasks.length
            "
            class="profileBox profileBox--2"
        >
            <div v-if="!isVisibleTest">
                <div v-if="Number($route.params.block_priority) === 10">
                    <p class="profileBox__title2">Написание сделки</p>
                    <div
                        v-html="dealsDescription"
                        class="profileBox__text2 singleContent"
                    ></div>
                </div>
                <div v-else>
                    <p class="profileBox__title2">Тест</p>
                    <p class="profileBox__text2">
                        При прохождении теста Вам доступна одна ошибка. После второй
                        ошибки тест не засчитывается и Вам нужно будет пересмотреть
                        видео-урок, чтобы сдать тест повторно. Количество попыток
                        прохождения теста не ограничено.
                    </p>
                </div>
                <Button
                    class="profileBox__btn2"
                    title="Начать"
                    :onClick="() => onStartTest()"
                />
            </div>

            <div v-if="isVisibleTest && currentAnswer">
                <div class="testProgress">
                    <div class="testProgressHeader">
                        <p class="title1 testProgressHeader__text textProbableBugFix">
                            Прогресс
                        </p>
                        <p
                            v-if="leftAnswersPhrase"
                            class="title1 testProgressHeader__text textProbableBugFix"
                        >
                            {{ leftAnswersPhrase }}
                        </p>
                    </div>
                    <div class="progressRail testProgressRail">
                        <div
                            v-if="answeredPercentage"
                            class="progressRail__total testProgressRail__total"
                            :style="'width: ' + answeredPercentage + '%'"
                        ></div>
                    </div>
                </div>

                <p
                    v-if="currentAnswer.ask"
                    class="profileBox__title2 textProbableBugFix"
                    v-html="
                        currentAnswer.ask
                            .replaceAll(/(?:\r)/g, ' \r')
                            .replaceAll(/(?:\r\n|\r|\n)/g, '<br/>')
                            .replace(/(https?:\/\/[^\s]+)/g, (url) => {
                                return `<a href='${url}' target='_blank'>${url}</a>`;
                            })
                    "
                ></p>

                <div
                    v-if="currentAnswer.type === 'test' && currentAnswer.tests"
                    class="form form--2 testForm"
                >
                    <div
                        v-for="item in currentAnswer.tests.options"
                        :key="item.answer"
                        class="formgroup"
                    >
                        <label class="formgroup__radioLabel">
                            <input
                                class="input--checkbox"
                                :value="item._id"
                                :name="'answer_' + 0"
                                :type="
                                    currentAnswer.type === 'test' &&
                                    currentAnswer.tests &&
                                    currentAnswer.tests.multiple
                                        ? 'checkbox'
                                        : 'radio'
                                "
                                v-model="
                                    $data[
                                        currentAnswer.type === 'test' &&
                                        currentAnswer.tests &&
                                        currentAnswer.tests.multiple
                                            ? 'testAnswerCheckbox'
                                            : 'testAnswerRadio'
                                    ]
                                "
                            />
                            <span class="colorGray formgroup__radioLabel_text">
                                {{ item.answer }}
                            </span>
                        </label>
                    </div>
                </div>

                <div
                    v-else-if="currentAnswer.type === 'free answer'"
                    class="form form--2 testForm"
                >
                    <div class="form profileBox__form2">
                        <div class="formgroup">
                            <TextArea
                                rows="7"
                                placeholder="Введите текст"
                                :optimalTextLength="200"
                                optimalLengthTooltip="Постарайтесь аргументировать свой ответ, но уместить его в 200 символов, для того, чтобы другим студентам было комфортно его читать."
                                :isError="isEmptyDescription || isDescriptionLong"
                                :errorText="
                                    isEmptyDescription
                                        ? 'Введите описание'
                                        : 'Вы не можете отправить более 1000 символов в этом текстовом поле'
                                "
                                :onChange="(e) => onChangeFreeAnswer(e)"
                            />
                        </div>

                        <div class="formgroup">
                            <InputZone
                                :receivedFiles="files"
                                :maxFiles="
                                    Number($route.params.block_priority) === 10 ? 10 : 5
                                "
                                fileType="image/*"
                                :maxSize="5"
                                :isError="isFileNotValid"
                                errorText="Вы не можете прикрепить файл этого формата, попробуйте прикрепить изображение"
                                :onFilesUpdate="(e) => updateFiles(e)"
                            />
                        </div>
                    </div>
                </div>

                <div class="profileBox__btn2 testFooter">
                    <Button
                        :title="
                            Number($route.params.block_priority) === 10
                                ? 'Отправить'
                                : 'Проверить'
                        "
                        :onClick="checkAnswer"
                    />
                    <p
                        v-if="Number($route.params.block_priority) !== 10"
                        :class="
                            mistakesCounter > 0 ? 'text2 colorRed' : 'text2 colorGray'
                        "
                    >
                        Допущено ошибок: {{ mistakesCounter }} из 1
                    </p>
                </div>
            </div>
        </div>

        <div
            v-else-if="lesson.homeworkProgress && lesson.homeworkProgress.complete"
            class="profileBox profileBox--2"
        >
            <div v-if="lesson.homework && lesson.homework.status === 'not checked'">
                <div v-if="Number($route.params.block_priority) === 10">
                    <p class="profileBox__title2">
                        Поздравляем! Вы успешно отправили свои сделки 🥳
                    </p>
                    <p class="profileBox__text2 profileBox__text2--2">
                        Вы получите уведомление об их проверке, а сам результат сможете
                        узнать тут сразу после проверки Ваших сделок
                    </p>
                </div>
                <div v-else>
                    <p class="profileBox__title2">
                        Вы отправили на проверку домашнее задание.
                    </p>
                    <p class="profileBox__text2 profileBox__text2--2">
                        Ожидайте, когда куратор проверит его.<br />
                    </p>
                </div>
            </div>

            <div v-else-if="lesson.homework && lesson.homework.status === 'declined'">
                <div v-if="Number($route.params.block_priority) === 10">
                    <p class="profileBox__title2 colorRed">
                        Внимание!
                    </p>
                    <p class="profileBox__text2 profileBox__text2--2">
                        Куратор отправил Вашу сделку на доработку.
                    </p>
                </div>
                <div v-else>
                    <p class="profileBox__title2 colorRed">
                        Внимание!
                    </p>
                    <p class="profileBox__text2 profileBox__text2--2">
                        Куратор отклонил Ваше домашнее задание.
                    </p>
                </div>

                <HomeworkReplies allowedFiles="images" />
            </div>

            <div v-else>
                <div v-if="Number($route.params.block_priority) === 10">
                    <p class="profileBox__title2 colorGreen">
                        Поздравляем! Ваша сделка была принята 🥳
                    </p>
                    <p class="profileBox__text2 profileBox__text2--2">
                        Можете быть довольны собой, это последняя точка Вашего обучения!
                        Поздравляем!
                    </p>
                </div>
                <div v-else>
                    <p class="profileBox__title2 colorGreen">
                        <span
                            v-if="
                                lesson &&
                                    lesson.homework &&
                                    lesson.homework.replies &&
                                    lesson.homework.replies.length
                            "
                        >
                            Куратор принял Ваш ответ, продолжайте в том же духе!
                        </span>
                        <span v-else>
                            Тест сдан.
                        </span>
                    </p>
                    <p class="profileBox__text2 profileBox__text2--2">
                        Поздравляем! Вы успешно сдали домашнее задание по этому уроку.
                    </p>
                    <div class="profileBox__btn2">
                        <Button
                            title="Оценить этот урок"
                            :onClick="() => onOpenNextLesson()"
                        />
                    </div>
                </div>

                <HomeworkReplies
                    v-if="
                        lesson &&
                            lesson.homework &&
                            lesson.homework.replies &&
                            lesson.homework.replies.length
                    "
                    allowedFiles="images"
                />
            </div>
        </div>

        <div v-else-if="isBlockedHomework" class="profileBox profileBox--2">
            <p class="profileBox__title2 colorRed">
                Тест не сдан
            </p>
            <p class="profileBox__text2 profileBox__text2--2">
                Вы дважды неправильно ответили на тест. Пожалуйста, пересмотрите урок еще
                раз, в нем содержится ответ. Через 30 минут у вас вновь появится
                возможность пройти тест и перейти к следующим урокам.
            </p>
            <div class="profileBox__btn2">
                <Button
                    title="пересмотреть урок"
                    :isLink="true"
                    :link="
                        '/' +
                            $route.params.slug +
                            '/study/' +
                            $route.params.block_priority +
                            '/' +
                            $route.params.lesson_id
                    "
                />
            </div>
        </div>

        <div
            v-if="
                isVisibleTest &&
                    lesson.showHWButton &&
                    !isBlockedHomework &&
                    currentAnswer &&
                    currentAnswer.type === 'test' &&
                    currentAnswer.tests &&
                    currentAnswer.tests.multiple
            "
            class="testTooltip"
        >
            <img
                class="testTooltip__icon"
                alt="infoBlue"
                src="@/assets/img/common/infoBlue.svg"
            />
            <span class="colorFiord">
                В этом вопросе возможно несколько правильных ответов
            </span>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import Input from "@/views/components/Input/Input";
import TextArea from "@/views/components/TextArea/TextArea";
import InputZone from "@/views/components/InputZone/InputZone";
import HomeworkReplies from "@/views/components/HomeworkReplies/HomeworkReplies";

export default {
    name: "test",

    components: {
        Button,
        Input,
        TextArea,
        InputZone,
        HomeworkReplies,
    },

    async beforeMount() {
        await this.updateIfBlockedHomeworkStore();
        await this.updateLessonStore();
        // await this.updateProgressStore();

        this.isVisibleTest =
            this.lesson.homeTasks.filter((task) => task.type === "free answer").length ===
            this.lesson.homeTasks.length;
        this.currentAnswer = this.lesson.homeTasks[0];
        this.leftAnswersPhrase = this.lesson.leftAnswersPhrase;
        this.answeredPercentage = this.lesson.answeredPercentage;
        this.isLoading = false;
    },

    watch: {
        "$route.params.lesson_id": async function() {
            await this.updateIfBlockedHomeworkStore();
            await this.updateLessonStore();
            await this.updateProgressStore();

            this.isLoading = true;
            this.isVisibleTest =
                this.lesson.homeTasks.filter((task) => task.type === "free answer")
                    .length === this.lesson.homeTasks.length;
            this.testAnswerRadio = "";
            this.testAnswerCheckbox = [];
            this.freeAnswer = "";
            this.files = [];
            this.mistakesCounter = 0;
            this.currentAnswer = this.lesson.homeTasks[0];
            this.leftAnswersPhrase = this.lesson.leftAnswersPhrase;
            this.answeredPercentage = this.lesson.answeredPercentage;
        },
    },

    computed: {
        lesson: function() {
            return this.$store.getters.getLesson && this.$store.getters.getLesson.lesson;
        },

        block: function() {
            return (
                this.$store.getters.getUserProgress &&
                this.$store.getters.getUserProgress.progress &&
                this.$store.getters.getUserProgress.progress.data[0] &&
                this.$store.getters.getUserProgress.progress.data[0].blocks[
                    Number(this.$route.params.block_priority) - 1
                ]
            );
        },

        isBlockedHomework: function() {
            return this.$store.getters.getIsBlockedHomework;
        },

        dealsDescription: function() {
            return (
                (this.lesson &&
                    this.lesson.lesson &&
                    this.lesson.lesson.lesson &&
                    this.lesson.lesson.lesson.descriptionFull) ||
                "Спустя 1 месяц после написания алгоритма у Вас есть возможность направить свои сделки на проверку Александру Герчику. Ответ Александра Михайловича будет отправлен Вам на почту в течение 2-х недель."
            );
        },

        // currentAnswer: function() {
        //     return (this.lesson && this.lesson.homeTasks[0]) || {};
        // },
        //
        // leftAnswersPhrase: function() {
        //     return (this.lesson && this.lesson.leftAnswersPhrase) || "";
        // },
        //
        // answeredPercentage: function() {
        //     return (this.lesson && this.lesson.answeredPercentage) || 0;
        // },
    },

    data() {
        return {
            isLoading: true,
            isVisibleTest: false,
            isFileNotValid: false,
            isDescriptionLong: false,
            isEmptyDescription: false,

            testAnswerRadio: "",
            testAnswerCheckbox: [],
            freeAnswer: "",
            files: [],
            mistakesCounter: 0,
            currentAnswer: {},
            leftAnswersPhrase: "",
            answeredPercentage: 0,
        };
    },

    methods: {
        onStartTest() {
            this.isVisibleTest = true;
        },
        onOpenNextLesson() {
            this.$router.push(
                `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${this.$route.params.lesson_id}/evaluation`
            );
        },

        async updateLessonStore() {
            const getLesson = await this.axios.get(
                "/programs/" +
                    this.$route.params.slug +
                    "/study/" +
                    this.$route.params.block_priority +
                    "/" +
                    this.$route.params.lesson_id
            );

            if (getLesson && getLesson.data && getLesson.data.result) {
                await this.$store.dispatch("setLesson", getLesson.data);
            } else {
                console.error("Ошибка при поиске урока: " + (getLesson.data.error || ""));
                await this.$store.dispatch("clearLesson");
                await this.$router.push("/" + this.$route.params.slug + "/study");

                if (getLesson.data && getLesson.data.error) {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: getLesson.data.error,
                        },
                    });
                }
            }
        },

        async updateIfBlockedHomeworkStore() {
            const getIsBlockedHomework = await this.axios.get(
                "/programs/" +
                    this.$route.params.slug +
                    "/study/" +
                    this.$route.params.block_priority +
                    "/" +
                    this.$route.params.lesson_id +
                    "/isBlockedMember"
            );

            if (
                getIsBlockedHomework &&
                getIsBlockedHomework.data &&
                !getIsBlockedHomework.data.result
            ) {
                await this.$store.dispatch("setIsBlockedHomework", true);
            } else {
                await this.$store.dispatch("clearIsBlockedHomework");
            }
        },

        async updateProgressStore() {
            const getProgress = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/progress"
            );

            if (getProgress && getProgress.data && getProgress.data.result) {
                await this.$store.dispatch("setUserProgress", getProgress.data);
            } else {
                console.error(
                    "Ошибка при поиске основных блоков: " + getProgress.data.error
                );
                await this.$store.dispatch("clearUserProgress");
            }
        },

        async updateAdditionalProgressStore() {
            const getAdditionalProgress = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/progress/additional"
            );

            if (
                getAdditionalProgress &&
                getAdditionalProgress.data &&
                getAdditionalProgress.data.result
            ) {
                await this.$store.dispatch(
                    "setUserAdditionalProgress",
                    getAdditionalProgress.data
                );
            } else {
                console.error(
                    "Ошибка при поиске дополнительных блоков: " +
                        (getAdditionalProgress.data.error || "")
                );
                await this.$store.dispatch("clearUserAdditionalProgress");
            }
        },

        async checkAnswer() {
            if (this.currentAnswer) {
                this.isDescriptionLong = this.freeAnswer.length > 1000;

                if (this.isDescriptionLong) {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Вы не можете отправить более 1000 символов в текстовом поле",
                        },
                    });
                } else {
                    const filesFormats = ["jpeg", "jpg", "png", "svg"];

                    const isValidFiles = this.files.map((item) =>
                        filesFormats.includes(item.filename.split(".")[1].toLowerCase())
                    );

                    if (isValidFiles.includes(true)) {
                        this.isFileNotValid = false;
                    }

                    if (isValidFiles.includes(false)) {
                        this.isFileNotValid = true;
                    }

                    // prettier-ignore
                    if ( this.testAnswerRadio ||
                        this.testAnswerCheckbox.length ||
                        this.freeAnswer && this.freeAnswer.length < 1000 &&
                        ((isValidFiles.includes(true) && !isValidFiles.includes(false)) ||
                            this.files.length === 0)
                    ) {
                        let type = "";
                        let answers = [];
                        let text = "";

                        if (this.currentAnswer.type === "test") {
                            type = "test";

                            if (this.testAnswerRadio) {
                                answers = [this.testAnswerRadio];
                            } else if (this.testAnswerCheckbox.length) {
                                answers = this.testAnswerCheckbox;
                            }
                        } else if (this.currentAnswer.type === "free answer") {
                            type = "free answer";
                            text = this.freeAnswer;
                        }

                        const data = {
                            type: type,
                            task_set_id: this.currentAnswer._id,
                            answers: answers,
                            text: text,
                            files: this.files,
                        };

                        const getAnswerResponse = await this.axios.post(
                            "/programs/" +
                            this.$route.params.slug +
                            "/study/" +
                            this.$route.params.block_priority +
                            "/" +
                            this.$route.params.lesson_id +
                            "/answer",
                            data
                        );
                        await this.updateIfBlockedHomeworkStore();
                        this.freeAnswer = "";

                        if (!this.isBlockedHomework) {
                            if (
                                getAnswerResponse &&
                                getAnswerResponse.data &&
                                getAnswerResponse.data.result
                            ) {
                                // this.updateLessonStore();

                                if (
                                    getAnswerResponse.data.addPoints ||
                                    this.currentAnswer.type === "free answer"
                                ) {
                                    if (getAnswerResponse.data.complete) {
                                        await this.updateLessonStore();
                                        await this.updateProgressStore();
                                        await this.updateAdditionalProgressStore();
                                    }

                                    await this.$store.dispatch("setAlert", {
                                        alert: {
                                            isActive: true,
                                            type: "success",
                                            text: getAnswerResponse.data.answerStatus,
                                        },
                                    });

                                    this.freeAnswer = "";
                                } else {
                                    this.mistakesCounter++;

                                    await this.$store.dispatch("setAlert", {
                                        alert: {
                                            isActive: true,
                                            type: "error",
                                            text: getAnswerResponse.data.answerStatus,
                                        },
                                    });

                                    if (this.mistakesCounter > 1) {
                                        await this.$store.dispatch("setIsBlockedHomework", true);
                                    }
                                }

                                this.currentAnswer = getAnswerResponse.data.nextTask;
                                this.leftAnswersPhrase = getAnswerResponse.data.leftAnswersPhrase;
                                this.answeredPercentage = getAnswerResponse.data.answeredPercentage;
                            } else {
                                await this.$store.dispatch("setAlert", {
                                    alert: {
                                        isActive: true,
                                        type: "error",
                                        text:
                                            (getAnswerResponse &&
                                                getAnswerResponse.data &&
                                                getAnswerResponse.data.error) ||
                                            "Ошибка при проверке теста",
                                    },
                                });
                            }
                        }

                        this.testAnswerRadio = "";
                        this.testAnswerCheckbox = [];
                        this.freeAnswer = "";
                        this.files = [];
                    } else {
                        await this.$store.dispatch("setAlert", {
                            alert: {
                                isActive: true,
                                type: "error",
                                text:
                                    this.currentAnswer.type === "test"
                                        ? "Вы не выбрали ни одного ответа"
                                        : "Поле с ответом пустое",
                            },
                        });
                    }
                }
            } else {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Ошибка при проверке домашнего задания. Пожалуйста, перезагрузите страницу"
                    },
                });
            }
        },

        onChangeFreeAnswer(event) {
            this.freeAnswer = event;
        },

        async updateFiles(passedFiles) {
            this.files = passedFiles;
        },
    },
};
</script>

<style lang="scss">
@import "./test.scss";
</style>
